const instagramFollowButtonClick = {
  eid: 'ux2.social-feed.instagram_follow_button.click',
  type: 'click'
};

const instagramPostClick = {
  eid: 'ux2.social-feed.instagram_post.click',
  type: 'click'
};

// add tracking to elements that are loaded async
const attachTrackingHandler = trackingData => {
  if (!window || !window._trfq) return;
  window._trfq.push(['cmdLogEvent', trackingData.type, trackingData.eid]);
};

export { instagramFollowButtonClick, instagramPostClick, attachTrackingHandler };
