/* eslint-disable react/no-find-dom-node */
/* eslint-disable complexity */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { Component, lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { omit, isEmpty } from 'lodash';
import { UX2, UX, constants as coreConstants } from '@wsb/guac-widget-core';
import dataAids from '../../constants/dataAids';
import { instagramFollowButtonClick, instagramPostClick } from '../../constants/tracking';
import { instagramConnectHelpLink } from '../../constants/externalLinks';
import { proxyApiCall } from '../utils';
import { DEVICE_TYPE, INSTAGRAM_LAYOUT_STYLES } from '../../constants';

const suspenseWrapper = children => {
  return typeof window !== 'undefined' ? <Suspense fallback={ null }>{ children }</Suspense> : null;
};
const Masonry = lazy(() => import('@wsb/guac-widget-shared/lib/components/Masonry'));

const { ADD, EDIT, DISPLAY, PUBLISH } = coreConstants.renderModes;

const breakpoints = {
  xs: 320,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1536
};

const ImageMaxWidth = {
  [DEVICE_TYPE.MOBILE]: '300px',
  [DEVICE_TYPE.TABLET]: '512px'
};

const MAX_PREVIEW_IMAGES = 6;
const { getTCCLString, attachTcclHandlers } = UX2.utils.TCCLUtils;
const { MatchMedia } = UX;
const defaultSize = '200';

class InstagramFeed extends Component {
  static propTypes = {
    category: PropTypes.string,
    linkLabel: PropTypes.string,
    accountId: PropTypes.string,
    websiteId: PropTypes.string,
    renderMode: PropTypes.string,
    viewDevice: PropTypes.string,
    env: PropTypes.string,
    isInstagramConnected: PropTypes.object,
    staticContent: PropTypes.object,
    defaultStockPhotos: PropTypes.arrayOf(PropTypes.string),
    isReseller: PropTypes.bool,
    setHeadingProps: PropTypes.func,
    maxPosts: PropTypes.number,
    layoutStyle: PropTypes.string
  };

  static defaultProps = {
    setHeadingProps: () => {},
    maxPosts: 4,
    layoutStyle: INSTAGRAM_LAYOUT_STYLES.GRID
  };

  handleMatchMedia = data => {
    const { viewDevice, renderMode } = this.props;
    const publishMode = renderMode === PUBLISH;
    const isMobile = publishMode
      ? data.size === 'xs' || data.size === 'sm'
      : /mobile/i.test(viewDevice);
    const device = isMobile ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.TABLET;
    if (device !== this.state.device) {
      this.setState({ device });
    }
  };

  constructor() {
    super(...arguments);
    this.state = {
      instagramPosts: false,
      mouseOverIndex: false,
      isInstagramConnected: this.props.isInstagramConnected,
      postDimensions: {},
      loading: true,
      invalidToken: false,
      device: DEVICE_TYPE.TABLET
    };
    this._nodeRefs = {
      wrapper: null,
      followButton: null,
      postReferences: []
    };
    this.getContainerInformation = this.getContainerInformation.bind(this);
    this.standardizeImageDimensions = this.standardizeImageDimensions.bind(this);
    this.setMouseOverIndex = this.setMouseOverIndex.bind(this);
  }

  componentDidMount() {
    this.initializeInstagramData();
    window.addEventListener('resize', this.standardizeImageDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.standardizeImageDimensions);
  }

  setMouseOverIndex(mouseOverIndex) {
    this.setState({ mouseOverIndex });
  }

  generateStyles(rendersFollowButton, postCount) {
    return {
      grid: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: '0px',
        ['@xs-only']: {
          justifyContent: postCount > 1 ? 'left' : 'center',
          marginVertical: '50px'
        }
      },
      zeroState: {
        color: 'highContrast',
        textAlign: 'center',
        ...(!rendersFollowButton
          ? {
            ['@xs-only']: {
              padding: 0
            }
          }
          : {})
      },
      loaderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        fontSize: 'medium',
        color: 'highContrast'
      },
      cell: {
        display: 'flex',
        justifyContent: 'center'
      },
      cellMasonry: {
        padding: 'xxsmall',
        width: '100%'
      },
      button: {
        display: 'inline-block'
      },
      buttonCell: {
        textAlign: 'center',
        paddingBottom: 0
      },
      image: {
        cursor: 'pointer',
        objectFit: 'cover',
        width: '100%',
        height: '1px',
        ['@md']: {
          width: '100%',
          height: '1px'
        }
      },
      overlay: {
        wrapper: {
          position: 'relative',
          width: '100%',
          height: '100%',
          zIndex: 1
        },
        wrapperMasonry: {
          position: 'absolute',
          top: 0,
          width: '100%',
          height: '100%',
          cursor: 'pointer'
        },
        coloredOverlay: {
          transition: 'all 0.5s',
          opacity: 0,
          width: '100%',
          height: '100%',
          ['@md']: {
            ':hover': {
              backgroundColor: 'sectionOverlay',
              opacity: 0.15
            }
          }
        },
        instagramIcon: {
          display: 'none',
          ['@md']: {
            display: 'block',
            position: 'absolute',
            fontColor: 'highContrast',
            bottom: '10px',
            right: '10px',
            transition: 'all 0.5s',
            zIndex: -1,
            cursor: 'pointer'
          }
        }
      },
      instagramConnectOverlay: {
        cellContainer: {
          position: 'absolute',
          zIndex: 1
        },
        titleImage: {
          maxHeight: '80px',
          maxWidth: '80%',
          marginBottom: 'xlarger',
          width: 'unset'
        },
        helperTextStyle: {
          fontFamily: 'GD Sherpa, Arial, sans-serif',
          fontSize: '16px',
          lineHeight: '26px'
        },
        helpLinkText: {
          fontFamily: 'GD Sherpa, Arial, sans-serif',
          fontSize: '16px',
          ['@xs-only']: {
            color: '#61EDEA'
          },
          [':hover']: {
            textDecoration: 'underline',
            color: '#61EDEA'
          }
        }
      }
    };
  }

  initializeInstagramData() {
    const { renderMode } = this.props;
    const { isInstagramConnected } = this.state;
    const afterPostData = () => {
      const postDomReferences = this._nodeRefs.postReferences
        .concat([this._nodeRefs.followButton])
        .filter(Boolean)
        .map(node => ReactDOM.findDOMNode(node));
      this.standardizeImageDimensions();
      renderMode === PUBLISH && attachTcclHandlers(postDomReferences);
    };

    const getCachedData = () =>
      this.requestData('GPSStats', response => {
        try {
          const cachedPosts = response.data.GPSStats.instagram.postsIndividual.mostRecent.value;
          (cachedPosts || []).length &&
            this.setState({ instagramPosts: cachedPosts, invalidToken: true });
          afterPostData();
        } catch (e) {
          this.setState({ isInstagramConnected: false });
        }
      });

    const getPostData = () =>
      this.requestData(
        'getPosts',
        response => {
          response &&
            this.setState({
              instagramPosts: response.payload && response.payload.posts,
              invalidToken: false
            });
          afterPostData();
        },
        getCachedData
      );

    if (isInstagramConnected) return getPostData();
    this.requestData('canGetConnection', hasConnection => {
      const { payload } = hasConnection;
      if (!payload.ok) {
        return this.setState({ isInstagramConnected: false, instagramPosts: [] });
      }
      this.requestData('getConnection', getConnection => {
        getConnection && this.setState({ isInstagramConnected: getConnection.payload });
      });
      getPostData();
    });
  }

  standardizePostData() {
    const { instagramPosts, postDimensions } = this.state;
    const { maxPosts, layoutStyle } = this.props;
    if (!instagramPosts.length) return [];
    const styles = this.generateStyles();

    if (layoutStyle === INSTAGRAM_LAYOUT_STYLES.MASONRY) {
      return instagramPosts
        .slice(0, maxPosts)
        .map(({ mediaURL, linkToPost, text, mediaType, thumbnailURL }, index) => {
          return {
            ['data-aid']: `${dataAids.INSTAGRAM_FEED_ITEM_RENDERED}_${index}`,
            linkToPost,
            alt: text,
            image: mediaType === 'VIDEO' ? thumbnailURL : mediaURL,
            index
          };
        });
    }

    return instagramPosts
      .slice(0, maxPosts)
      .map(({ mediaURL, linkToPost, text, mediaType, thumbnailURL, additionalData }, index) => {
        return {
          ['data-aid']: `${dataAids.INSTAGRAM_FEED_ITEM_RENDERED}_${index}`,
          onClick: () => {
            window && window.open(linkToPost);
          },
          alt: text,
          style: {
            ...styles.image,
            ...postDimensions,
            backgroundImage: `url('${mediaType === 'VIDEO' ? thumbnailURL : mediaURL}')`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          },
          mediaType,
          additionalData
        };
      });
  }

  requestData(proxyApiMethod, onSuccessCB, onErrorCB) {
    const { accountId, renderMode, websiteId, env } = this.props;
    const url = proxyApiCall({
      provider: 'instagram',
      accountId: renderMode !== PUBLISH && accountId,
      method: proxyApiMethod,
      env,
      websiteId
    });

    if (proxyApiMethod === 'GPSStats') {
      return window
        .fetch(url)
        .then(response => response.text())
        .then(responseText => onSuccessCB(JSON.parse(responseText)))
        .catch(e => e && onErrorCB && onErrorCB());
    }

    const xhr = new XMLHttpRequest();
    xhr.onreadystatechange = () => {
      this.setState({ loading: false });
      if (xhr.readyState === 4 && xhr.status === 200) {
        const response = JSON.parse(xhr.responseText);
        onSuccessCB(response);
      }
      if (xhr.status >= 400) {
        // on any error
        onErrorCB && onErrorCB();
      }
    };
    xhr.open('GET', url, true);
    xhr.withCredentials = true;
    this.setState({ loading: true }, () => {
      xhr.send(null);
    });
  }

  standardizeImageDimensions() {
    const { renderMode } = this.props;
    if (!this._nodeRefs.postReferences.length) return;
    if (renderMode === DISPLAY) {
      return this.setState({
        postDimensions: { 'height': defaultSize + 'px', '@md': { height: defaultSize + 'px' } }
      });
    }
    const postDomReferences = this._nodeRefs.postReferences
      .map(node => {
        try {
          return ReactDOM.findDOMNode(node);
        } catch (e) {
          return false;
        }
      })
      .filter(Boolean);
    if (!postDomReferences.length) return;
    const renderedRect = postDomReferences[0].getBoundingClientRect();
    let editorOffset = 1;
    if (renderMode !== PUBLISH) {
      const { containerWidth, breakpoint } = this.getContainerInformation();
      editorOffset = containerWidth >= breakpoint ? 1 : containerWidth / breakpoint;
    }
    const imageHeight = renderedRect.width / editorOffset;

    this.setState({
      postDimensions: imageHeight && {
        height: imageHeight + 'px',
        ['@md']: {
          height: imageHeight + 'px'
        }
      }
    });
  }

  getContainerInformation() {
    if (!this._nodeRefs.wrapper) return 1;
    const wrapperNode = ReactDOM.findDOMNode(this._nodeRefs.wrapper);
    const parentContainer = document.querySelectorAll(
      `[data-aid='${dataAids.DESKTOP_RENDER_CONTAINER}'], [data-aid='${dataAids.WIDGET_WRAPPER_SOCIALFEED}'], [data-aid='${dataAids.MOBILE_RENDER_CONTAINER}']`
    );
    const container = Array.from(parentContainer).find(el => el.contains(wrapperNode));
    if (!container) return 1;
    const force = (
      Array.from(container.querySelector('.x')?.classList || []).find(c =>
        c.startsWith('x-force-')
      ) || ''
    ).substr(-2);
    const target = breakpoints[force] || breakpoints.md;
    return { containerWidth: container.offsetWidth, breakpoint: target };
  }

  getUserName() {
    const { isInstagramConnected } = this.state;
    return isInstagramConnected ? isInstagramConnected.additionalData.username : null;
  }

  generateDefaultPosts() {
    const { defaultStockPhotos, maxPosts } = this.props;
    const { postDimensions } = this.state;
    const styles = this.generateStyles();
    const postSize = isEmpty(postDimensions)
      ? null
      : postDimensions && postDimensions.height && postDimensions.height.split('px')[0];
    const size = postSize || defaultSize;
    return (
      (defaultStockPhotos &&
        defaultStockPhotos.slice(0, maxPosts).map((url, index) => ({
          ['data-aid']: `${dataAids.INSTAGRAM_FEED_ITEM_RENDERED}_${index}`,
          mediaURL: url,
          image: url, // for masonry view support
          mediaType: 'IMAGE',
          style: {
            ...styles.image,
            height: `${size}px`,
            ['@md']: {
              height: `${size}px`
            },
            backgroundImage: `url('${url}/:/rs=h:${defaultSize}/cr=w:${defaultSize},h:${defaultSize},a:cc')`
          }
        }))) ||
      []
    );
  }

  renderOverlay(imageIndex) {
    const { category } = this.props;
    const { mouseOverIndex } = this.state;
    const { overlay } = this.generateStyles();

    return (
      <UX2.Element.Block
        style={ overlay.wrapper }
        onMouseEnter={ () => this.setMouseOverIndex(imageIndex) }
        onMouseLeave={ () => this.setMouseOverIndex(-1) }
        category={ category }
      >
        <UX2.Element.Block style={ overlay.coloredOverlay } />
        <UX2.Element.Icon
          category='accent'
          style={{ ...overlay.instagramIcon, opacity: imageIndex === mouseOverIndex ? 1 : 0 }}
          size='xlarge'
          icon='instagram'
        />
      </UX2.Element.Block>
    );
  }

  renderLoader() {
    const { loaderContainer } = this.generateStyles();
    return (
      <UX2.Element.Block style={ loaderContainer }>
        <UX2.Element.Loader />
      </UX2.Element.Block>
    );
  }

  generateDashboardLink() {
    const { isReseller, env, accountId } = this.props;
    const getRootDomain = () => {
      const ENV_CONFIG = {
        reseller: {
          local: 'dev-secureserver.net',
          development: 'dev-secureserver.net',
          test: 'test-secureserver.net',
          production: 'secureserver.net'
        },
        godaddy: {
          local: 'dev-godaddy.com',
          development: 'dev-godaddy.com',
          test: 'test-godaddy.com',
          production: 'godaddy.com'
        }
      };
      const config = isReseller ? ENV_CONFIG.reseller : ENV_CONFIG.godaddy;
      return config[env];
    };
    const currentUrl = window.location.href;
    return `https://social.manager.${getRootDomain()}/instagram?redirectToProviderLogin=true&accountId=${accountId}&redirectURL=${currentUrl}`;
  }

  renderInstagramConnectOverlay() {
    const { staticContent, setHeadingProps, renderMode } = this.props;
    const { invalidToken } = this.state;
    const { instagramConnectOverlay } = this.generateStyles();
    const titleImageSrc = '//img1.wsimg.com/isteam/ip/static/instagramBusinessLogoAlt.png';
    setHeadingProps({ style: { filter: 'blur(10px)' } });

    // typography='none' used to ignore typography styles on EDIT mode only overlay
    const overlayProps = {
      renderMode,
      beforeContent: (
        <React.Fragment>
          <UX2.Element.Image style={ instagramConnectOverlay.titleImage } src={ titleImageSrc } />
          <UX2.Element.Text
            typography='none'
            style={ instagramConnectOverlay.helperTextStyle }
            children={
              staticContent[
                invalidToken ? 'reconnectInstagramOverlayContentText' : 'facebookOverlayContentText'
              ]
            }
          />
        </React.Fragment>
      ),
      button: {
        typography: 'none',
        onClick: e => e.stopPropagation(),
        href: this.generateDashboardLink(),
        children: staticContent[invalidToken ? 'reconnectButtonText' : 'connectButtonText'],
        ['data-event']: `Social Feed Instagram ${
          invalidToken ? 'Reconnect' : 'Connect'
        } Click (overlay)`
      },
      afterContent: (
        <UX2.Element.Link
          typography='none'
          style={ instagramConnectOverlay.helpLinkText }
          href={ instagramConnectHelpLink }
          children={ staticContent.howToLinkInstagram }
          data-event='Social Feed Instagram Connect Help Link Click (overlay)'
        />
      )
    };

    if (invalidToken) {
      delete overlayProps.afterContent;
    }

    return <UX2.Component.ZeroStateOverlay { ...overlayProps } />;
  }

  renderZeroState(styles) {
    const { staticContent = {} } = this.props;

    return (
      <UX2.Component.Grid.Cell md={ 12 } xs={ 12 } style={ styles }>
        <UX2.Element.Text children={ staticContent.comingSoon } />
      </UX2.Component.Grid.Cell>
    );
  }

  renderMasonryLayout(postCount, postData, styles, shouldRenderFacebookOverlay) {
    const { renderMode } = this.props;
    const { loading, instagramPosts, device } = this.state;
    const renderingDefaultContent = renderMode === ADD || shouldRenderFacebookOverlay;
    if (!postCount && !loading && !renderingDefaultContent && Array.isArray(instagramPosts)) {
      return this.renderZeroState(styles.zeroState);
    }

    let pushXs, pushMd;

    let filteredPosts = postData;
    // UX feedback: Reduce the images to 6 if either showing the overlay or in the Add section view
    if (
      shouldRenderFacebookOverlay ||
      (postCount && renderMode === ADD && postCount > MAX_PREVIEW_IMAGES)
    ) {
      filteredPosts = postData.slice(0, MAX_PREVIEW_IMAGES);
    }

    const imagesLen = filteredPosts.length;
    if (imagesLen < 3) {
      pushXs = imagesLen === 1 ? 3 : null;
      pushMd = imagesLen === 1 ? 4 : 2;
    }

    const imageMaxWidth = ImageMaxWidth[device];
    styles.overlay.wrapperMasonry.maxWidth = imageMaxWidth; // overlay max width

    return (
      <UX2.Element.Block
        style={{
          width: '100%',
          maxWidth: '100%',
          minWidth: '100%',
          marginLeft: '-xxsmall',
          marginRight: '-xxsmall'
        }}
      >
        { suspenseWrapper(
          <Masonry device={ device }>
            { filteredPosts.map((imageData, imageIndex) => (
              <UX2.Component.Grid.Cell
                key={ imageIndex }
                style={{
                  ...styles.cell,
                  ...styles.cellMasonry,
                  ...(shouldRenderFacebookOverlay ? { filter: 'blur(10px)', opacity: '0.3' } : {})
                }}
                md={ 4 }
                xs={ 6 }
                pushXs={ pushXs }
                pushMd={ pushMd }
              >
                <UX2.Element.Block style={{ maxWidth: imageMaxWidth }}>
                  <UX2.Element.Image
                    tag='img'
                    data-aid={ imageData['data-aid'] }
                    style={{ cursor: 'pointer', width: '100%', height: 'auto' }}
                    imageData={ imageData }
                    data-tccl={ getTCCLString(instagramPostClick) }
                  />
                  <UX2.Element.Block
                    onClick={ () => {
                      window && window.open(imageData.linkToPost);
                    } }
                    style={ styles.overlay.wrapperMasonry }
                  >
                    { this.renderOverlay(imageIndex) }
                  </UX2.Element.Block>
                </UX2.Element.Block>
              </UX2.Component.Grid.Cell>
            )) }
          </Masonry>
        ) }
      </UX2.Element.Block>
    );
  }

  renderGridLayout(postCount, postData, styles, shouldRenderFacebookOverlay) {
    const { maxPosts, renderMode } = this.props;
    const { loading, instagramPosts, postDimensions } = this.state;
    const placeholderDimensions = {
      minHeight: `${defaultSize}px`
    };
    const renderingDefaultContent = renderMode === ADD || shouldRenderFacebookOverlay;
    return (
      <UX2.Component.Grid>
        { typeof window === 'undefined' ||
        loading ||
        (!renderingDefaultContent && !Array.isArray(instagramPosts))
          ? [...Array(maxPosts)].map((_, i) => (
            <UX2.Component.Grid.Cell md={ 3 } xs={ 6 } key={ i } style={ placeholderDimensions } />
          ))
          : postCount
            ? postData.map((imageData, imageIndex) => (
              <UX2.Component.Grid.Cell
                key={ imageIndex }
                style={{
                  ...styles.cell,
                  ...(shouldRenderFacebookOverlay ? { filter: 'blur(10px)', opacity: '0.3' } : {}),
                  ...(!postDimensions && placeholderDimensions)
                }}
                md={ 3 }
                xs={ 6 }
              >
                <UX2.Component.Background
                  { ...omit(imageData, ['mediaType', 'additionalData']) }
                  ref={ backgroundRef => this._nodeRefs.postReferences.push(backgroundRef) }
                  data-tccl={ getTCCLString(instagramPostClick) }
                  children={ this.renderOverlay(imageIndex) }
                />
              </UX2.Component.Grid.Cell>
            ))
            : this.renderZeroState(styles.zeroState) }
      </UX2.Component.Grid>
    );
  }

  render() {
    const { linkLabel, renderMode, setHeadingProps, layoutStyle } = this.props;
    const { loading, isInstagramConnected, invalidToken } = this.state;

    const username = this.getUserName();
    const rendersFollowButton = linkLabel && username;
    const shouldRenderFacebookOverlay =
      renderMode === EDIT && (!isInstagramConnected || invalidToken);
    // render stock photos when not connected or in ADD mode
    const postData =
      renderMode === ADD || shouldRenderFacebookOverlay
        ? this.generateDefaultPosts()
        : this.standardizePostData();
    const postCount = postData && postData.length;
    const styles = this.generateStyles(rendersFollowButton, postCount);
    if (postCount) {
      this._nodeRefs.postReferences = [];
    }
    if (!shouldRenderFacebookOverlay) {
      setHeadingProps({ 'data-route': 'sectionTitle' });
    }

    return (
      <React.Fragment>
        <MatchMedia onChange={ this.handleMatchMedia } />
        { loading && this.renderLoader() }
        <UX2.Component.Grid
          style={{ ...styles.grid, minHeight: shouldRenderFacebookOverlay ? '400px' : 'unset' }}
          spacingSm='medium'
          spacing='medium'
          ref={ grid => {
            this._nodeRefs.wrapper = grid;
          } }
        >
          { shouldRenderFacebookOverlay && (
            <UX2.Component.Grid.Cell
              bottom={ false }
              md={ 10 }
              spacing='0px'
              style={ styles.instagramConnectOverlay.cellContainer }
            >
              { this.renderInstagramConnectOverlay() }
            </UX2.Component.Grid.Cell>
          ) }
          { layoutStyle === INSTAGRAM_LAYOUT_STYLES.GRID
            ? this.renderGridLayout(postCount, postData, styles, shouldRenderFacebookOverlay)
            : this.renderMasonryLayout(postCount, postData, styles, shouldRenderFacebookOverlay) }
          { rendersFollowButton && (
            <UX2.Component.Grid.Cell md={ 12 } xs={ 12 } style={ styles.buttonCell }>
              <UX2.Element.MoreLink.Forward
                role='button'
                target='_blank'
                data-aid={ dataAids.SOCIALFEED_FOLLOW_LINK_RENDERED }
                style={ styles.button }
                children={ linkLabel }
                ref={ self => {
                  this._nodeRefs.followButton = self;
                } }
                data-tccl={ getTCCLString(instagramFollowButtonClick) }
                href={ `https://www.instagram.com/${username}` }
              />
            </UX2.Component.Grid.Cell>
          ) }
        </UX2.Component.Grid>
      </React.Fragment>
    );
  }
}

export default InstagramFeed;
