/* eslint-disable */

// load cached instagram posts
export const gpsStatsApiCall = ({ websiteId, env }) => {
  const options = encodeURI(`query GPSStats {
    GPSStats(id: "${websiteId}") {
      instagram {
        postsIndividual {
          mostRecent {
            value
          }
        }
      }
    }
  }`);
  return `https://proxy.apps-api.instantpage.${
    env !== 'production' ? `${env}-` : ''
  }godaddy.com/v1/proxy/gpsStats?query=${options}`;
};

export const proxyApiCall = ({ provider, accountId, websiteId, env, method }) => {
  if (!(provider || accountId || websiteId || env || method)) return '';
  if (env === 'local' || env === 'development') {
    env = 'dev';
  }
  if (method === 'GPSStats') {
    return websiteId && gpsStatsApiCall({ websiteId, env });
  }
  return `https://proxy.apps-api.instantpage.${
    env !== 'production' ? `${env}-` : ''
  }godaddy.com/v1/proxy/social-sdk?accountId=${accountId || ''}&provider=${provider}${
    websiteId ? `&websiteId=${websiteId}` : ''
  }&method=${method}`;
};
